import React, { Component } from 'react'
import Layout from '../components/layout/layout'
import SEO from '../components/seo'
import Banner from '../components/banner';
import CategoryType from '../components/category-type';
import Product from '../components/product';
import "../components/product.css"
import CategoryInformation from '../components/categoryInformation';
import { StaticQuery, graphql } from 'gatsby'
import KitchenShapeSelection from '../components/kitchen-shape-selection';
import SeoText from '../components/browse-designs-seo-text';
import { BrowseDesignsSeoText, KitchenTags } from '../components/layout/strings';

const sizeFilters = [];
const themeFilters = [];

class Kitchen extends Component {

  constructor(props){
    super(props);
    this.state = {
      sizeFilters,
      themeFilters
    };
  }

  componentDidMount(){
    this.setState({
      sizeFilters : [ 
        {value: false, label: "Small", id: 1},
      {value: false, label: "Medium", id: 2},
      {value: false, label: "Large", id: 3},
    ],
    themeFilters : [
      {value: false, label: "Contemporary", id: 1},
      {value: false, label: "Modern", id: 2},
      {value: false, label: "Rustic", id: 3},
      {value: false, label: "Antique", id: 4},
      {value: false, label: "Minimalist", id: 5},
      {value: false, label: "Traditional", id: 6},
      {value: false, label: "Blended", id: 7},
      ],
      isSizeOpen : false,
      isThemeOpen : false,
      sizePlaceholder : "Select Sizes",
      themePlaceholder : "Select Themes",
      isSizeChartOpen : false
    });
      }

  render(){
    return(
      <Layout selectedPage = "browseDesigns">
          <SEO 
            title={KitchenTags.title}
            description={KitchenTags.description} />

          {
            console.log(this.props.data.allMarkdownRemark.edges)
          }

          <div>
            <Banner image={this.props.data.banner.childImageSharp.fluid}/>
    
            <div className="roomsAndFilterSelection">
              <CategoryType selectedCategory="Kitchen"/>
            </div>
      
            <KitchenShapeSelection />

            <div
              className="productOuterContainer"
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center'
              }}>
    
                {
                  this.props.data.allMarkdownRemark.edges.map(({ node, index}) => (
                    <Product frontmatter={node.frontmatter}
                      slug={node.fields.slug}
                      sizeFilters = {this.state.sizeFilters}
                      themeFilters = {this.state.themeFilters}
                      id = {node.id}/>
                  ))
                }
         
            </div>

            <SeoText data = {BrowseDesignsSeoText.kitchen}/>
          </div>
      </Layout>
      )
    }
  }

  class KitchenStaticQuery extends Component {
    render() {
      return (
      <StaticQuery
      query={graphql`
      query {
        allMarkdownRemark (
        filter: { fileAbsolutePath: {regex : "\/straight-kitchen/"} },
          sort: {fields: [frontmatter___date], order: DESC},
        ){
          totalCount
          edges {
            node {
              ...Product
              fields {
                  slug
              }
            }
          }
        }
        banner: file(relativePath: { eq: "banner_kitchen.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1980) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      `}
            render={data => <Kitchen data ={ data}/>}/>
      );
   }
  }

export default KitchenStaticQuery
